<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
                <app-left-side></app-left-side>
            </div>
        </div>
        <main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <div class="px-4 py-3">
                    <div class="card rounded-4 mb-4 bg-white card-box">
                        <div class="card-head d-flex">
                            <header class="flex-grow-1">Blog Categories List</header>
                            <div class="tools">
                                <button type="button" class="btn btn-outline-secondary add-btn" (click)="showAddModal(content, 'Blog')">
                                Add Category
                            </button>

                                <button class="accordion-button with-chevron btn-icon" type="button">
                                <i class="fa fa-cog"></i>
                            </button>
                                <button class="accordion-button with-chevron btn-icon" type="button" data-bs-toggle="collapse" data-bs-target="#tbcollapse" aria-expanded="true" aria-controls="collapseOne">
                                <i class="fa fa-angle-down"></i>
                            </button>
                            </div>
                        </div>
                        <div class="card-body p-0" id="tbcollapse">
                            <div class="table-wrap">
                                <div class="table-responsive">
                                    <table class="table table-hover display product-overview mb-30">
                                        <thead>
                                            <tr>
                                                <th class="serial">S.No</th>
                                                <th class="title">Category Name</th>
                                                <th class="category">Slug</th>
                                                <th class="category">Addded On</th>
                                                <th class="actions">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let c of categories; let i = index">
                                                <td class="serial"> {{ i+1 }} </td>
                                                <td class="title"> {{ c.title }} </td>
                                                <td class="category"> {{ c.cslug }} </td>
                                                <td class="staus"> {{ c.createdat | date:'medium'}} </td>
                                                <td class="actions">
                                                    <a href="javascript:void(0)" class="tblEditBtn" (click)="showEdit(content, c)">
                                                        <i class="fa fa-pencil"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ action}} Category</h5>
            <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
        </div>
        <div class="modal-body">
            <div class="">
                <div class="card-body">
                    <form class="p-0" [formGroup]="blogForm">
                        <div class="content" [hidden]="step != 1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Title *</label>
                                        <input type="text" formControlName="title" class="form-control bg-light" [ngClass]="{ 'is-invalid': errStep1 && f.title.errors }" placeholder="Title*" />
                                        <div *ngIf="errStep1 && f.title.errors" class="invalid-feedback">
                                            <div *ngIf="f.title.errors.required">Title is required.</div>
                                            <div *ngIf="(f.title.hasError('maxlength') || f.title.hasError('minlength')) && !f.title.hasError('required')">
                                                Title should be 4 - 50 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group text-center">
                                    <button (click)="submitModal(modal)" class="btn btn-main">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>
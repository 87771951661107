import { BrowserModule, Meta, Title } from "@angular/platform-browser";
import * as $ from "jquery";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { NgxCaptchaModule } from "ngx-captcha";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { FilterPipeModule } from "ngx-filter-pipe";
import { AutoCompleteInputDirective } from "./directives/AutoCompleteInputDirective";
import { ForgetpasswordComponent } from "./pages/forgetpassword/forgetpassword.component";
import { LoginComponent } from "./pages/login/login.component";
import { RecreatepasswardComponent } from "./pages/recreatepassward/recreatepassward.component";
import { RegisterComponent } from "./pages/register/register.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LeftSideComponent } from "./pages/left-side/left-side.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { Config } from "./services/config";
import { AppAuth } from "./services/app-auth.service";
import { AppHttp } from "./services/app-http.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BlogListComponent } from "./pages/blog-list/blog-list.component";
import { BlogCategoriesComponent } from "./pages/blog-categories/blog-categories.component";
import { EmployeeListComponent } from "./pages/employee-list/employee-list.component";
import { EmployeeProfileComponent } from "./pages/employee-profile/employee-profile.component";
//import { CKEditorModule } from 'ckeditor4-angular';
//import { ChartsModule } from "ng2-charts";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SortableHeaderDirective } from "./directives/sortable.directive";
import { TableService } from "./services/table.service";
import { FileUpload } from "./components/file-upload/file-upload.component";
import { TagInput } from "./components/tag-input/tag-input.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ReceiptListComponent } from "./pages/receipt-list/receipt-list.component";
//import { ReceiptDataTableComponent } from './components/receipt-data-table/receipt-data-table.component';
import { AppService } from "./services/app.service";
//import { NgxDatatableModule } from '@tusharghoshbd/ngx-datatable';
//import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppCsvService } from "./services/appcsv.service";
import { DataTablesModule } from "angular-datatables";

@NgModule({
    declarations: [
        ForgetpasswordComponent,
        LoginComponent,
        AppComponent,
        RecreatepasswardComponent,
        RegisterComponent,
        HeaderComponent,
        FooterComponent,
        FileUpload,
        TagInput,
        AutoCompleteInputDirective,
        DashboardComponent,
        LeftSideComponent,
        ProfileComponent,
        ResetPasswordComponent,
        BlogListComponent,
		BlogCategoriesComponent,
        EmployeeListComponent,
        EmployeeProfileComponent,
        SortableHeaderDirective,
        ReceiptListComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CommonModule,
        //NgxCaptchaModule,
        SlickCarouselModule,
        FilterPipeModule,
        //CKEditorModule,
        AngularEditorModule,
        //ChartsModule,
        BrowserAnimationsModule, // required animations module
        CKEditorModule,
        ToastrModule.forRoot({
            timeOut: 1000,
            positionClass: "toast-bottom-right",
            preventDuplicates: true,
        }), // ToastrModule added
        NgbModule,
        DataTablesModule,
        //Ng2SmartTableModule
    ],
    providers: [Config, AppAuth, AppHttp, TableService, DecimalPipe, DatePipe, Meta, Title, AppService, AppCsvService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AppAuth } from "../../services/app-auth.service";
import { Config } from "src/app/services/config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-blog-categories",
    templateUrl: "./blog-categories.component.html",
    styleUrls: ["./blog-categories.component.css"],
})
export class BlogCategoriesComponent implements OnInit {
    blogForm: FormGroup;
    step: number = 1;
    errStep1: boolean = false;
    errStep2: boolean = false;
    submitting: boolean = false;
    action: string = "Add";
    temp: any = {};
    ckeConfig: any;
    mycontent: string;
    selectedType: string = "Blog";
    log: string = "";
    @ViewChild("myckeditor") ckeditor: any;
    categories: Array<any> = [];
    categoriesMain: Array<any> = [];
    statusList = [];
    Additionalimage: any;
    localUrl: any;
    htmlContent = "";
    descriptionLength: any = 500;
    titleLength: any = 250;
    blogs = {
        options: {
            limit: 10,
            pageno: 1,
            total: 0,
            offset: 0,
            search: "",
            cslug: "",
        },
        list: [],
    };
    public componentEvents: string[] = [];
    localImg: any;
    bs64: any;
    html: any;
    public configs = {
        //  toolbar: [ 'link' ],
        link: {
            decorators: {
                isExternal: {
                    mode: "manual",
                    label: "Open in a new tab",
                    attributes: {
                        target: "_blank",
                    },
                },
            },
        },
    };

    constructor(public formBuilder: FormBuilder, public toastr: ToastrService, public auth: AppAuth, public config: Config, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.blogForm = this.formBuilder.group({
            title: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(100)]],
        });
        this.getCategoryList();
    }

    get f() {
        return this.blogForm.controls;
    }

    getCategoryList() {
        this.auth.getCategories().then(
            (cats: any) => {
                this.categoriesMain = cats;
                this.categories = this.categoriesMain.filter((a) => a.title != "News");
            },
            (err: any) => {
                this.toastr.error(err.title, err.detail);
            }
        );
    }
    showAddModal(content: any, type: any) {
        this.step = 1;
        this.action = "Add";
        this.blogForm.reset();
        this.temp = null;
        this.modalService.open(content, { size: "lg", scrollable: false });
    }

    showEdit(content: any, blog: any) {
        this.step = 1;
        this.action = "Edit";
        this.blogForm.reset();
        this.temp = blog;
        this.setFormValues(blog);
        this.modalService.open(content, { size: "lg", scrollable: false });
    }

    private setFormValues(blog: any) {
        let b = this.blogForm.controls;
        b.title.setValue(blog.title);
    }

    submitModal(ref: any) {
        let c = this.blogForm.controls;

        if (this.blogForm.valid) {
            this.config.showLoading();
            if (this.temp) {
                let updateCat = {
                    categoryid: this.temp.categoryid,
                    title: c.title.value,
                };
                this.auth.updateBlogCategory(updateCat).then(
                    (res: any) => {
                        this.getCategoryList();
                        ref.close();
                        this.config.dismissLoading();
                    },
                    (err: any) => {
                        this.config.dismissLoading();
                        this.toastr.error(err.title, err.detail);
                    }
                );
            } else {
                let updateCat = {
                    title: c.title.value,
                };
                this.auth.addBlogCategory(updateCat).then(
                    (res: any) => {
                        this.getCategoryList();
                        ref.close();
                        this.config.dismissLoading();
                    },
                    (err: any) => {
                        this.config.dismissLoading();
                        this.toastr.error(err.title, err.detail);
                    }
                );
            }
        } else {
            this.toastr.error("Enter Valid Text");
        }
    }
}

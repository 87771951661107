import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ForgetpasswordComponent } from "./pages/forgetpassword/forgetpassword.component";
import { LoginComponent } from "./pages/login/login.component";
import { RecreatepasswardComponent } from "./pages/recreatepassward/recreatepassward.component";
import { RegisterComponent } from "./pages/register/register.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AuthGuard } from "./shared/auth.guard";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { AutoLoginGuard } from "./shared/auto-login.guard";
import { BlogListComponent } from "./pages/blog-list/blog-list.component";
import { BlogCategoriesComponent } from "./pages/blog-categories/blog-categories.component";
import { EmployeeListComponent } from "./pages/employee-list/employee-list.component";
import { EmployeeProfileComponent } from "./pages/employee-profile/employee-profile.component";
import { ReceiptListComponent } from "./pages/receipt-list/receipt-list.component";

const routes: Routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
        data: {
            title: "Daani | Login",
        },
    },
    {
        path: "recreatepassward",
        component: RecreatepasswardComponent,
        data: {
            title: "Daani | Recreate Password",
        },
    },
    {
        path: "forgetpassword",
        component: ForgetpasswordComponent,
        data: {
            title: "Daani | Forgot Password",
        },
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [AutoLoginGuard],
        data: {
            title: "Daani | Register",
        },
    },
    {
        path: "login",
        pathMatch: "prefix",
        component: LoginComponent,
        canActivate: [AutoLoginGuard],
        data: {
            title: "Daani | Login",
        },
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Dashboard",
        },
    },
    {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Profile",
        },
    },
    {
        path: "change-password",
        component: ResetPasswordComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Change Password",
        },
    },
    {
        path: "blog-list",
        component: BlogListComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Blog List",
        },
    },
    {
        path: "blog-categories",
        component: BlogCategoriesComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Blog Categories",
        },
    },
    {
        path: "emp-list",
        component: EmployeeListComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Employee List",
        },
    },
    {
        path: "employee-profile",
        component: EmployeeProfileComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Employee Profile",
        },
    },
    {
        path: "receipt-list",
        component: ReceiptListComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Daani | Donation List",
        },
    },
    {
        path: "**",
        redirectTo: "/login",
        pathMatch: "full",
        data: {
            title: "Daani | Login",
        },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "enabled",
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

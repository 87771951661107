<ul id="sidebar-nav" class="list-group">
    <li class="list-group-item active" *ngIf="(auth.currentUser?.roles[0] ==  'admin') || (auth.currentUser?.roles[0] == 'superadmin')">
        <a routerLink="/dashboard" class="text-link">
            <i class="icon-material-outline-dashboard mr-3 left-side py-3"></i> Dashboard
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0] ==  'user'">
        <a routerLink="/profile" class="text-link">
            <i class="icon-line-awesome-user mr-3 left-side py-3"></i> Account
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0] ==  'superadmin'">
        <a routerLink="/emp-list" class="text-link">
            <i class="fa fa-users  mr-3 left-side py-3"></i> User List
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0]=='superadmin' || auth.currentUser?.roles[0]=='seoteam' || auth.currentUser?.roles[0]=='seoteamlead'">
        <a routerLink="/blog-list" [queryParams]="{selectedType: 'Blog'}" class="text-link">
            <i class="fa fa-pencil-square-o  mr-3 left-side py-3"></i> Blog List
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0]=='superadmin' || auth.currentUser?.roles[0]=='seoteam' || auth.currentUser?.roles[0]=='seoteamlead'">
        <a routerLink="/blog-categories" [queryParams]="{selectedType: 'Blog'}" class="text-link">
            <i class="fa fa-pencil-square-o  mr-3 left-side py-3"></i> Blog Categories
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0]=='superadmin' || auth.currentUser?.roles[0]=='seoteam' || auth.currentUser?.roles[0]=='seoteamlead'">
        <a routerLink="/blog-list" [queryParams]="{selectedType: 'News'}" class="text-link">
            <i class="fa fa-newspaper-o  mr-3 left-side py-3"></i> News List
        </a>
    </li>
    <li class="list-group-item" *ngIf="auth.currentUser?.roles[0] ==  'superadmin'">
        <a routerLink="/receipt-list" class="text-link">
            <i class="fa fa-file-text-o  mr-3 left-side py-3"></i> Receipt List
        </a>
    </li>
</ul>